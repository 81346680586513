export default function flipCardsInit() {
  const cards = document.querySelectorAll('.card.published .card-image-container')
  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        setTimeout(() => {
          entry.target.classList.add('flip')
          observer.unobserve(entry.target)
        }, 1000)
      }
    })
  })

  cards.forEach(card => observer.observe(card))
  // add transition delay to each card
  cards.forEach((card, index) => {
    // get card flipper div
    const flipper = card.querySelector('.card-flipper')
    // set transition delay

    if (flipper instanceof HTMLElement) {
      flipper.style.transitionDelay = `${index * 0.2}s`
    }
  })
}
