import * as $ from 'jquery'
import 'popper.js'
import 'bootstrap'
import WCC from 'wcc'
import { set } from 'mockdate'

import { toggleVisibleByCss } from '../javascripts/lib/showHide'
import { autoplayInit } from '../javascripts/application/autoplay'
import campusInit from '../javascripts/application/campus'
import emailCapture from '../javascripts/application/email-capture'
import eventsPageInit from '../javascripts/application/events-page'
import fullWidthHero from '../javascripts/application/full-width-hero'
import ga_collapse from '../javascripts/application/ga_collapse'
import initiativesCarousel from '../javascripts/application/initiatives-carousel'
import previewInit from '../javascripts/application/preview'
import scriptureQuote from '../javascripts/application/scripture-quote'
import sectionMenuScroll from '../javascripts/application/section-menu-scroll'
import { sharingInit } from '../javascripts/application/sharing'
import { signInInit } from '../javascripts/application/sign-in'
import toastInit from '../javascripts/application/toast'
import transcript from  '../javascripts/application/transcript'
import { viewportInit } from '../javascripts/application/viewport'
import { parallax } from '../javascripts/application/parallax'
import flipCardsInit from '../javascripts/application/flip-cards'
import modalOpenerInit, { modalVideoCloserInit } from '../javascripts/application/modal'
import reelPlayerInit from '../javascripts/application/reel-player'

import '../../../vendor/github.com/wistia/fresh-url'

// This has to happen before `numeric-filters.tsx` gets loaded
if (WCC.mockDate) {
  set(WCC.mockDate)
}

$(document).ready(() => {
  // JS needed for functionality
  campusInit()
  emailCapture()
  scriptureQuote()
  ga_collapse()
  initiativesCarousel()
  fullWidthHero()
  sectionMenuScroll()
  transcript()
  previewInit()
  toastInit()
  eventsPageInit()
  signInInit()
  autoplayInit()
  viewportInit()
  sharingInit()
  parallax()
  celebrate25Init()

  // https://stackoverflow.com/a/13170350/2192243
  $(document.body).on('click', '[data-href]', function() {
    const href = $(this).data('href')
    if (href && href.length > 0) {
      window.location = href
    }
  })

  // Top nav dropdown headers which are themselves a link
  //  1st click - open the dropdown.
  //  2nd click - follow the link.
  $('a[href][data-toggle="dropdown"]').on('click', function() {
    if ($(this).attr('aria-expanded') == 'true') {
      const href = $(this).attr('href')
      if (href && href.length > 0) {
        window.location = href as any
      }
    }
  })

  // Need to rerun reftagger once the async load is done.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  $(document).on('render_async_load', function(_event: any) {
    if(window.refTagger?.tag) {
      window.refTagger.tag(document.body)
    }
  })

  // https://getbootstrap.com/docs/4.3/components/tooltips/
  if ($.prototype.tooltip) {
    $('[data-toggle="tooltip"]').tooltip()
  }

  // show/hide tabs by day of week
  toggleVisibleByCss(`[data-weekday="${new Date().getDay()}"]`, '[data-weekday]')

  function celebrate25Init() {
    $('.widget-stories-card-deck').length && flipCardsInit()
    $('.story-modal').length && modalOpenerInit()
    $('.reel').length && reelPlayerInit()
    $('.modal-video, .modal-reel').length && modalVideoCloserInit()
  }
})
