export default function modalOpenerInit() {
  // opens modal when the URL includes the modal id

  const modalId = window.location.hash

  if (modalId) {
    const modal = document.querySelector(modalId)

    if (modal instanceof HTMLElement) {
      // scroll to stories-card-deck
      const story = document.querySelector('a.story-card[data-target="' + modalId + '"]')
      if (story instanceof HTMLElement) {
        setTimeout(() => {
          story.scrollIntoView({ behavior: 'smooth', block: 'end' })
        }, 250)
      }

      $(modal).modal('toggle')
    }
  }
}

export function modalVideoCloserInit() {
  const videoModals = document.querySelectorAll('.modal-video, .modal-reel')

  if (videoModals.length > 0) {
    videoModals.forEach((modal) => {
      const video = modal.querySelector('video')
      $(modal).on('hidden.bs.modal', () => {
        if (video instanceof HTMLVideoElement) {
          video.pause()
        }
      })
    })
  }
}
