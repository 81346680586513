export default function reelPlayerInit() {
  // do this for every reel
  const reels = document.querySelectorAll('.section-video-content__video.reel')

  reels.forEach((reel) => {
    const controls = reel.querySelector('.controls')
    const video = reel.querySelector('video')
        
    if (controls && video) {
      controls.setAttribute('data-state', 'visible')

      const playpause = controls.querySelector('.playpause')

      if (playpause) {
        playpause.addEventListener('click', () => {
          if (video.paused || video.ended) {
            video.play()
          } else {
            video.pause()
          }
        })

        video.addEventListener('play', () => {
          changeButtonState(playpause, 'playpause', video)

          if (isMobile()) {
            video.requestFullscreen()
          }
        })
    
        video.addEventListener('pause', () => {
          changeButtonState(playpause, 'playpause', video)
        })

        video.addEventListener('click', () => {
          if (video.paused || video.ended) {
            video.play()
          } else {
            video.pause()
          }

          changeButtonState(playpause, 'playpause', video)
        })
      }


    }
  })

  function changeButtonState(button: Element, type: string, video: HTMLVideoElement) {
    if (type === 'playpause') {
      if (video.paused || video.ended) {
        button.setAttribute('data-state', 'play')
      } else {
        button.setAttribute('data-state', 'pause')
      }
    }
  }

  function isMobile() {
    if (typeof window == 'undefined') {
      return false
    }
    return window.matchMedia('(max-width: 575.98px)').matches
  }
}